import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { Layout } from '../../components/Layout'
import { components } from '../../slices'

function RentalItem(props) {
  console.log('RentalItem props: ', props)
  const { prismicRentalItem } = props.data
  const rentalItem = prismicRentalItem.data
  const seo = {
    title: prismicRentalItem.data.seo_title || prismicRentalItem.data.title.text,
    description: prismicRentalItem.data.seo_description,
    keywords: prismicRentalItem.data.seo_keywords,
    no_robots: prismicRentalItem.data.no_robots,
  }
  console.log('seo: ', seo)


  const {title, body} = rentalItem
  const cntx = {title, restLink:rentalItem.restaraunt_link};
  console.log('cntx: ', cntx)

  const topMenu = props.data.prismicTopMenu || {}
  const footer = props.data.prismicFooter || {}

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={seo}>
      <SliceZone slices={body} components={components} context={cntx}/>
    </Layout>
  )
}

export default RentalItem

export const query = graphql`
  query($id: String!) {
    prismicRentalItem(id: { eq: $id }) {
      id
      type
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title
        restaraunt_link {
          id
          uid
          link_type
          type
          url
          document {
            ... on PrismicRestaurantDetail {
              id
              data {
                body {
                  ... on PrismicRestaurantDetailDataBodyRestaurantDetail {
                    id
                    slice_type
                    primary {
                      address
                      rest_name
                      logo {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ...PrismicRentalItemRentalInfo
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`